.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  height: 100px;
  background-color: #133e4a;
}
.footer .logo {
  width: 150px;
}
.footer-link {
  margin-right: 20px;
}
.footer-link-img {
  width: 40px;
  height: 40px;
}

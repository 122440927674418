* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}
.App {
  max-width: 1400px;
  margin: 0 auto;
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
  background-color: #249898;
}

.tile-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.tile-body {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  padding: 64px 96px;
  background: rgb(116, 116, 116);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(71, 71, 71, 0.5) 60%, rgba(255, 255, 255, 0) 100%);
  height: 100%;
}

.tile-body h4 {
  font-size: 46px;
  color: #fff;
  margin-bottom: 16px;
  max-width: 550px;
}
.tile-body p {
  font-size: 18px;
  color: #fff;
  max-width: 550px;
  margin-bottom: 32px;
}
.tile-body .tile-button {
  font-size: 18px;
  color: #fff;
  margin-top: auto;
  width: 200px;
}

.tile-background {
  height: 450px;
  width: 100%;
  object-fit: cover;
}

.header-wrapper {
  display: flex;
  align-items:center;
  height: 120px;
  padding: 16px 32px;
  background-color: #133e4a;
}
.logo-wrapper {
  margin-right: 16px;
  padding: 8px;
}
.logo {
  max-width: 250px;
}
.header-search {
  margin-left: auto;
}
.xl.movie {
  position: relative;
  width: 220px;
  border: 1px solid #28afab;
  transition: 0.3s ease-in;
  margin: 16px;
  border-radius: 4px;
  overflow: hidden;
}
.sm.movie {
  position: relative;
  width: 100%;
  border: 1px solid #28afab;
  transition: 0.3s ease-in;
  margin: 0;
  border-radius: 4px;
  overflow: hidden;
}
.movie-info {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  height: 100px;
  color: #fff;
}
.movie-img {
  width: 100%;
  min-height: 330px;
  object-fit: cover;
}
.sm.movie .movie-img {
  width: 100%;
  height: 200px;
  min-height: unset;
  object-fit: cover;
}
.movie-title {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
}
.movie-release-date {
  font-size: 14px;
}
.rating {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  flex: 0 0 50px;
}
.movie-rating {
  position: absolute;
  right: 8px;
  top: 8px;
}
.xl.movie:hover {
  transform: scale(1.05);
  cursor: pointer;
  box-shadow: 0px 0px 10px 5px rgb(49 221 211 / 20%);
}
.sm.movie:hover {
  transform: unset;
  cursor: pointer;
  box-shadow: 0px 0px 10px 5px rgb(49 221 211 / 20%);
}
.high-rating {
  background-color: #47d147;
}
.middle-rating {
  background-color: #f8a50d;
}
.low-rating {
  background-color: #ff0707;
}
.none-rating {
  background-color: #0d2a33;
}

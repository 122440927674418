.movie-details {
  padding: 64px;
}
.movie-details-body {
  display: flex;

  flex-wrap: nowrap;
  color: #fff;
}
.movie-details-left-side {
  flex: 0 1 auto;
  margin-right: 32px;
}
.movie-details-right-side {
  flex: 1 1 60%;
  display: flex;
  flex-direction: column;
}
.movie-details-img {
  width: 300px;
  border: 1px solid #28afab;
  border-radius: 4px;
  overflow: hidden;
}
.movie-details-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.movie-details-header h2 {
  font-size: 48px;
  margin-right: 16px;
  flex: 1;
}
.movie-details-header .rating {
  width: 50px;
  height: 50px;
  font-size: 20px;
}
.movie-details-description {
  font-size: 18px;
}
.movie-details-about-film {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}
.movie-details-about-film dl {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10ch, 1fr));
  grid-gap: 16px;
  margin-top: 16px;
}
.movie-details-about-film dl dt {
  grid-column-start: 1;
}
.movie-details-about-film dl dd {
  grid-column-start: span 4;
}

.search-form {
  display: flex;
  justify-content: center;
}
.search-filter {
  display: flex;
  justify-content: center;
}
.search-input {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  min-width: 100px;
  width: 200px;
  height: 40px;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  background-color: #FFF;
  transition: all .5s ease-in-out;
  border-radius: 4px;
  outline: none;
  border: 3px solid transition;
}
.search-input:focus {
  width: 350px;
  max-width: unset;
  outline: none;
  border-color: #2cc6bf;
}
.search-input:hover {
  border-color: #2cc6bf;
}

.wrapper {
  width: 100%;
  height: 100%;
  background-color: #0d2a33;
  min-height: 100vh;
}
.container {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  box-shadow: 0px 0px 45px 0px rgb(49 221 211 / 50%);
}
